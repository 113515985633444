/* Register Page Button CSS */
input.sub-btn:hover {
  background: #00c849 !important;
  border: 1px solid #00c849 !important;
}
input.sub-btn {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 30px;

  top: 37px;
  border: 1px solid #009d39;
  background: #009d39 !important;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}
input#submit1 {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 30px;
  top: 37px;
  border: 1px solid #009d39 !important;
  background: #009d39 !important;
  text-align: center;
  margin: 0 auto;
}
input#submit1:hover {
  background: #00c849 !important;
  border: 1px solid #00c849 !important;
}
/* Survey Page Button CSS */
input.submit-btn.survey {
  background: #009d39;
  outline: none;
  color: #fff;
  padding: 7px 15px !important;
  border: 1px solid #009d39;
  border-radius: 50px;
}
input.submit-btn.survey:hover {
  background: #00c849;
  border: 1px solid #00c849;
}
input.sub-btn1 {
  background: #009d39 !important;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #009d39 !important;
  border-radius: 50px;
}

input.sub-btn1:hover {
  background: #00c849 !important;
  border: 1px solid #00c849 !important;
}

button#btn1 {
  margin-right: 45%;
  color: #fff !important;
  border: 1px solid #009d39;
  border-radius: 30px;
  outline: none !important;
  background-color: #009d39 !important;
}
button#btn1:hover {
  background: #00c849 !important;
  outline: none !important;
  border: 1px solid #00c849 !important;
}

a.btn.btn-md.btn-primary {
  position: static !important;
  outline: none !important;
  border-radius: 30px !important;

  height: 46px !important;
  padding: 8px !important;
  width: 118px !important;
  background: #009d39 !important;
  border: 1px solid #009d39 !important;
  color: #fff !important;
  float: right !important;
}

a.btn.btn-md.btn-primary:hover {
  background: #00c849 !important;
  border: 1px solid #00c849 !important;
  text-decoration: none !important;
}

input.sub-btn2 {
  background: #009d39 !important;
  border: 1px solid #009d39 !important;
  outline: none;
  color: #fff;
  padding: 11px 15px;
  border-radius: 30px;
  width: 100%;
}
input.sub-btn2:hover {
  background: #00c849 !important;
  border: 1px solid #00c849 !important;
}
#Thanku_Buttons {
  background-color: #009d39;
  border-color: #009d39;
  color: #fff !important;
  text-decoration: none !important;
}
#Thanku_Buttons:hover {
  background: #00c849 !important;
  border: 1px solid #00c849 !important;
}

button#pop-close {
  background-color: #00c849;
  border: none;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
